.verify-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
}

.verify-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
}

.verify-img img {
    width: 250px;
}

.verify-err {
    color: red;
}

.verify-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.verify-input input {
    padding: 5px 10px;
    text-align: center;
    font-size: 25px;
    border: 1px solid #000;
    border-radius: 3px;
}

.verify-input button {
    background-color: transparent;
    border: none;
    color: #2277ef;
}

.verify-btn-container button {
    padding: 10px 20px;
    font-size: 19px;
}