.tf {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    text-align: center;
    gap: 5px;
    width: 100%;
}

.tf .tf-back {
    height: 100px;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
}

.tf .tf-btn {
    text-decoration: none;
}

.tf .tf-back > a {
    text-decoration: none;
    font-size: 25px;
    color: #000;
}

.tf .tf-btn {
    width: 100%;
}

.tf button {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 90%;
    margin: 0 auto;
    padding: 15px 10px;
    background-color: #fff;
    color: #000;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}

.tf button big {
    font-size: 20px;
}

.tf button small {
    color: #c4c4c4;
}

.tf .fas {
    background-color: #2277ef;
    width: 50px;
    height: 50px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@media (min-width: 1000px) {
    .tf {
        width: 50%;
        margin: 0 auto;
    }
}