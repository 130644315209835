.sent-msg {
    background-color: #389a1b;
    color: #fff;
    padding: 10px 15px;
}

.pend-msg {
    padding: 10px 15px;
    background-color: yellow;
}

.isverified {
    text-align: center;
    padding: 15px 5px;
    background-color: #d1d10b;
}

.home-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(118, 117, 117, 0.8);
    z-index: 555555555555555;
}

.home-modal-content {
    background-color: #fff;
    padding: 20px 15px;
    text-align: center;
}

.home-modal-content button {
    padding: 15px 20px;
    background-color: #389a1b;
    border: 1px solid #389a1b;
    border-radius: 5px;
    font-weight: 700;
    color: #fff;
}