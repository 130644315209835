@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
