.info {
    margin: 0 auto;
    padding: 0 20px;
}

.info a {
    text-decoration: none;
    color: #000 !important;
}

.info i {
    font-size: 35px;
}

.info button {
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    gap: 15px;
    border: 1px solid transparent;
    border-radius: 0 0 10px 10px;
    border-top-color: #eee;
    background-color: #fff;
    color: #000 !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.info .info-txt {
    font-size: 20px;
}

@media (min-width: 1000px) {
    .info {
        width: 60%;
        margin: 0 auto;
    }
}