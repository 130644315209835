.myinfo {
    width: 90%;
    margin: 0 auto !important;
}

.myinfo .myinfo-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    height: 150px;
}

.myinfo .myinfo-list-group {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
}

.myinfo .myinfo-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 70px;
    padding: 0 10px;
}

.myinfo span {
    font-weight: 700;
}

.myinfo-img-container {
    text-align: center;
    padding: 25px 0;
}

.myinfo img {
    width: 150px;
}

.myinfo .myinfo-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto !important;
    padding-bottom: 20px;
}

.myinfo button {
    padding: 10px 25px;
    font-size: 25px;
}