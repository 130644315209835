.n-nav {
    padding: 10px 20px;
}

.n-nav button {
    padding: 10px 20px;
    font-size: 20px;
}

.n-card {
    padding: 10px 20px;
}