nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2277ef;
    border: none !important;
    padding: 0 10px;
}

nav img {
    width: 35px;
    height: 35px;
    border: 2px solid #c4c4c4;
    border-radius: 50%;
}

nav .right-nav  {
    display: flex;
    gap: 5px;
    position: relative;
}

nav a {
    position: relative;
    text-decoration: none;
    color: #fff;
    padding: 15px 10px !important;
}

nav .fa, nav .fa-solid {
    font-size: 24px !important;
}

nav .count {
    position: absolute;
    top: 8px;
    right: 3px;
    background-color: red;
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid red;
    font-size: 10px;
}

@media (min-width: 1000px) {
    nav {
        justify-content: space-evenly;
    }
}