.r-card {
    background-color: #2277ef;
    padding-top: 15px;
}

.r-nav {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    text-align: center;
    border-radius: 50px 50px 0 0;
    background-color: #fff;
    padding: 15px 0;
}

.r-nav img {
    width: 200px;
}

.r-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.13);
}

.r-card-body li {
    padding-left: none;
    list-style: none;
    padding: 20px 0;
}

.r-card-body li:nth-child(odd) {
    text-align: left !important;
}

.r-card-body li:nth-child(even) {
    text-align: right !important;
}

.r-btn {
    padding: 15px 25px;
    width: 100%;
    font-size: 23px;
}

@media (min-width: 1000px) {
    .r-nav {
        width: 30%;
        margin: 0 auto;
    }
}