.loader-container {
    width: 100%;
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    animation: spin .9s linear infinite;
}

@-webkit-keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}