.ext {
    width: 90%;
    margin: 0 auto;
}

.ext-back {
    padding: 15px 0;
}

.ext-back a {
    text-decoration: none;
    color: #000;
    font-size: 25px;
}

.ext-form {
    padding: 10px 0;
}

.ext-form-group {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 15px;
}

.ext-form-group input,
.ext-form-group textarea {
    padding: 10px 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}

.ext-form-group input:focus,
.ext-form-group textarea:focus {
    border-color: #2277ef;
    outline: none;
}

.ext-form-group textarea {
    height: 100px;
}

.ext-btn-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    margin: 0 auto !important;
    padding: 10px 0;
}

.ext-btn-container button {
    width: 100%;
    padding: 15px 25px;
    font-size: 23px;
    background-color: #2277ef;
    border: 2px solid #2277ef;
    border-radius: 5px;
    color: #fff;
}

.ext-btn-container button:focus {
    border-color: #3b8af8;
    background-color: #3b8af8;
}

#acctMsg,
#rnMsg,
#amtMsg {
    color: #D20F0F;
}

.ext-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.19);
    z-index: 222222;
}

@media (min-width: 1000px) {
    .ext,
    .ext-btn-container {
        width: 50%;
        margin: 0 auto;
    }
}