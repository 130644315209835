.reg-main {
    height: 100vh;
    background-color: #2277ef;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.reg-main .reg-form-group {
    width: 90%;
}

.reg-main input {
    padding: 15px 5px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 24px;
}

.reg-main input::placeholder {
    color: #000;
    font-size: 25px;
}

.reg-main > .btn-container {
    display: flex;
    justify-content: flex-end;
    width: 90%;
}

.reg-main .btn-container > button {
    padding: 10px 20px;
    background-color: #D20F0F;
    color: #fff;
    font-size: 20px;
    border: 2px solid #D20F0F;
    border-radius: 5px;
}