footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid #eee;
    background-color: #fff;
}

footer div {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

footer .fa {
    font-size: 24px !important;
}

footer a {
    text-decoration: none;
    color: #000;
}

footer a small {
    color: #000;
}

footer a .footer-right {
    color: #000;
}

footer a .footer-right i {
    font-size: 24px;
}

footer a .footer-right small {
    color: #000;
}