.profile-nav {
    padding: 10px 20px;
}

.profile-nav button {
    padding: 10px 20px;
}

.profile-img-container {
    padding: 15px 0;
}

.profile-img-container .label {
    width: 50%;
    margin: 0 auto;
}

.profile-img {
    position: relative;
    height: 250px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c4c4c4;
    cursor: pointer;
    overflow: hidden;
}

.profile-img img {
    width: 100%;
    height: 350px;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
}

.profile-img i {
    position: absolute;
    font-size: 44px;
    z-index: -1;
}

.profile-btn-container {
    padding: 15px 0;
    text-align: center;
}

.profile-btn-container button {
    padding: 15px 25px;
    font-size: 18px;
}

.profile-text {
    width: 90%;
    margin: 0 auto;
}

@media (max-width: 550px) {
    .profile-img,
    .profile-img-container .label {
        width: 90%;
    }
}