.login-main-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.login-new {
    /* grid-area: x1; */
    padding: 10px 20px;
}

.login-main {
    /* grid-area: x2; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-main .img-container {
    padding-bottom: 25px;
    text-align: center;
}

.login-main img {
    width: 250px;
    margin-top: 20px;
}

.login-main .form {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 15px 20px;
}

.login-main .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.login-main .form-group .sub-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
}

.login-main .form-group .sub-group > input {
    width: 100%;
}

.login-main input,
.login-main button {
    padding: 15px 5px;
    border: 1px solid #000;
    border-radius: 5px;
}

.login-main .btn {
    background-color: #2277ef;
    border-color: #2277ef;
    color: #fff;
    font-size: 20px;
}

.login-main .new-btn {
    width: 100%;
    border-color: #1ca131;
    background-color: #1ca131;
    color: #fff;
    font-size: 20px;
}

.login-main #pass {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.login-main .sub-btn {
    border-left: none;
    background-color: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.form-verify  {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-verify input {
    font-size: 22px;
    text-align: center;
}

.form-verify button {
    background-color: #2277ef;
    border-color: #2277ef;
    color: #fff;
    font-size: 18px;
}

.emailErrMsg,
.passErrMsg {
    color: #D20F0F;
}

.login-img img {
    display: none;
}

.login-main .errBorder {
    border: 1px solid #D20F0F !important;
}

.login-main .errBorder::placeholder {
    color: #D20F0F;
}

.login-main .errBorder:focus {
    border: 1.2px solid #D20F0F;
    outline: none;
}

.login-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(34, 119, 239, 0.16);
}

@media (min-width: 1000px) {
    .login-main-new {
        grid-template-columns: repeat(2, 1fr);
    }

    .login-main {
        height: 100vh;
    }

    #form-width-70 {
        width: 70%;
    }

    #form-width-40 {
        width: 40%;
    }

    .login-img img {
        display: block;
    }
}

@media (max-width: 600px) {
    .login-main-new  {
        padding-bottom: 25px !important;
    }
}