.recent {
    margin: 0 auto !important;
    padding: 0 20px;
}

.card {
    width: 95%;
    margin: 15px auto !important;
    /* box-shadow: 0 2px 30px rgba(0, 0, 0, 0.12); */
    padding: 10px 15px;
    border-radius: 10px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
}

.card-header a {
    color: #000;
    text-decoration: none;
}

.card-body {
    width: 100%;
    margin-bottom: 15px;
}

.card-body a {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    color: #000;
    text-decoration: none;
    margin-bottom: 10px;
    border-bottom: 1px solid #c4c4c4;
    padding: 15px 0;
}

.card-amount .card-name {
    width: 100px;
}

.card-amount .card-date {
    color: #c4c4c4;
    font-size: 10px !important;
}

.card-body i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    background-color: #389a1b;
    color: #fff;
    border: 1px solid #389a1b;
    border-radius: 50%;
}

.card-body .card-amount {
    text-align: right;
    width: 100%;
}

.recent-loader {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1000px) {
    .recent {
        width: 60%;
        margin: 0 auto;
    }
}

@media (max-width: 290px) {
    .recent .card-name {
        display: none;
    }
}