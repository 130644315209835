.main-amt {
    background-color: #2277ef;
    border: none !important;
    padding: 0 20px;
}

.main-amt hr {
    background-color: #eee;
    border: none;
    height: 0.7px;
}

.main-amt a {
    text-decoration: none;
    color: #000;
}

.amount {
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    color: #fff;
}

.amount-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px 0;
}

.amount-nav a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
}

.amount-nav a > i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    font-size: 16px;
    background-color: #fff;
    color: #000 !important;
    border-radius: 50%;
    overflow: hidden;
}

.amount-transfer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    background-color: #fff;
    width: 95%;
    margin: 0 auto;
    padding: 15px 10px;
    font-size: 19px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 15px 15px 0 0;
}

.amount-transfer .fa-right-left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}

.amount-transfer .amount-txt {
    text-align: left !important;
}

.main-float {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 222222;
    padding: 10px 15px;
    background-color: #fff;
}

@media (min-width: 1000px) {
    .main-amt {
        width: 60%;
        margin: 0 auto;
    }
}

@media (max-width: 500px) {
    .amount #amt-Fig {
        font-size: 26px;
    }
}

@media (max-width: 350px) {
    .amount #amt-Fig {
        font-size: 20px;
    }
}