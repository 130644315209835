.transaction {
    width: 100%;
}

.t-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    height: 80px;
}

.t-nav i {
    font-size: 20px;
}

.t-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    text-decoration: none;
    color: #000;
}

.t-icon-container i {
    height: 40px;
    width: 40px;
    border: 1px solid #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.t-txt-container {
    width: 100%;
    padding-left: 10px;
}

.t-loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1000px) {
    .transaction {
        width: 50%;
        margin: 0 auto;
    }
}