.confirm-alert {
    padding: 10px;
}

.confirm-alert i {
    font-size: 25px;
    color: #fff;
}

.confirm-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 0;
}

.confirm-details li {
    list-style: none;
    padding-left: none;
    padding: 15px 10px;
    font-size: 19px;
}

.confirm-details li:nth-child(even) {
    text-align: right;
    font-weight: 700;
}

.confirm-btn-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 10px;
}

.confirm-btn {
    padding: 15px 20px;
    width: 100%;
    background-color: #2277ef;
    color: #fff;
    border: 1px solid #2277ef;
    border-radius: 5px;
    font-size: 19px;
}